<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-alphabetical-variant"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Faseamento</div>
      </template>
      <v-btn
        v-if="accessReleased('FASEAMENTO_ADICIONAR')"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('add')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Executar
      </v-btn>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <v-divider class="mt-10" />
      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhuma execução de faseamento encontrada"
      >
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }} v{{ item.versao }}
        </template>
        <template v-slot:[`item.ctmts_selecionados`]="{ item }">
          <div
            class="clickable-item"
            @click="openDialogCtmtsSelecionados(item.ctmts_selecionados)"
          >
            {{ item.ctmts_selecionados | exibeResumoCtmtsSelecionados }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="
              !['SUCESSO', 'FALHA'].includes(item.status) ? 'cursor-block' : ''
            "
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="() => baixarLogsTecnicos(item.id)"
            :loading="loadingLogsTecnicos[item.id]"
            :disabled="!['SUCESSO', 'FALHA'].includes(item.status)"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="canExecuteItem(item.status)"
                @click="executeItem(item.id)"
              >
                <v-list-item-title>
                  <v-icon small> mdi-play </v-icon>
                  Executar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item)"
              >
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Deletar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogCreationLog(item)">
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
              >
                mdi-refresh
              </v-icon>
            </template>
            <span>
              Clique aqui para recarregar as execuções de faseamentos
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <v-dialog
      v-model="dialogCtmtsSelecionados"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>CTMTS selecionados</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          <v-textarea
            class="ta-padding-c"
            rows="10"
            no-resize
            background-color="#EEEEEE"
            :readonly="true"
            :value="dialogCtmtsSelecionadosData.join('\n')"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="green darken-1"
            @click="dialogCtmtsSelecionados = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">
        Deletar Faseamento #{{ dialogDeleteData.id }}
      </template>
      <template slot="body">
        Tem certeza que deseja deletar a execução de faseamento
        <strong> #{{ dialogDeleteData.id }} </strong>?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import FaseamentoService from '@/services/FaseamentoService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue')
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Título',
        value: 'nome'
      },
      {
        text: 'Mês dos dados',
        value: 'data_registro'
      },
      {
        text: 'Tipo de algoritmo',
        value: 'tipo_algoritmo_txt'
      },
      {
        text: 'CTMTS',
        value: 'ctmts_selecionados'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ],
    search: '',
    dialogCtmtsSelecionados: false,
    dialogCtmtsSelecionadosData: [],
    dialogDelete: false,
    dialogDeleteData: { id: null },
    loadingLogsTecnicos: {},
    downloadProgressData: {},
    loadingLogsTecnicos: {}
  }),
  methods: {
    canExecuteItem(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    executeItem(id) {
      if (!confirm(`Tem certeza que deseja executar novamente #${id}?`)) {
        return;
      }
      FaseamentoService.executar(id)
        .then(() => {
          this.$toast.info(
            'A execução ocorrerá em instantes. Por favor, aguarde.',
            '',
            { position: 'topRight', timeout: 2000 }
          );
          this.$emit('rechargeTable');
        })
        .catch(() => {
          this.$toast.error('Erro ao executar o faseamento.', '', {
            position: 'topRight'
          });
        });
    },
    canDeleteItem(item) {
      return item.status === 'AGUARDANDO';
    },
    openDialogCtmtsSelecionados(ctmtsSelecionados) {
      this.dialogCtmtsSelecionadosData = ctmtsSelecionados;
      this.dialogCtmtsSelecionados = true;
    },
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.usuario,
        created: item.created
      };
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id
      };
    },
    deletarItem(id) {
      FaseamentoService.deletar(id)
        .then(() => {
          this.$toast.success(
            'Execução de faseamento removida com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar a execução de faseamento.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDelete = false;
    },
    baixarLogsTecnicos(faseamentoId) {
      this.loadingLogsTecnicos[faseamentoId] = true;
      FaseamentoService.baixarLogsTecnicos(faseamentoId)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `logs-tecnicos-faseamento-${faseamentoId}.log`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download dos logs técnicos.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingLogsTecnicos[faseamentoId] = false));
    }
  },
  filters: {
    exibeResumoCtmtsSelecionados(arrayCtmts) {
      let formattedValue = arrayCtmts[0].toUpperCase();
      let lengthArray = arrayCtmts.length;
      formattedValue =
        lengthArray > 1
          ? `${formattedValue} + ${lengthArray - 1}`
          : formattedValue;
      return formattedValue;
    }
  }
};
</script>
