<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <faseamento-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getAll"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FaseamentoService from '@/services/FaseamentoService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import FaseamentoDataTable from '@/components/perdas-tecnicas/faseamento/FaseamentoDataTable';

export default {
  mixins: [refreshDataMixins],
  components: {
    FaseamentoDataTable
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getAll);
  },
  methods: {
    getAll() {
      this.loading = true;
      FaseamentoService.getAll()
        .then(({ data }) => (this.items = data.data))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
